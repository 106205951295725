import { defineStore } from 'pinia'

export const useBillingTransactionStore = defineStore('billing_transaction', {
    state: () => ({
        transactions: [],
        media_purchases: [],
        subscriptions: [],
        tips: [],
        wallets: [],
        dm_texts: [],
        live_shows: [],
        timeReload: [],
        isPayWithWallet: false,
        loading: false,
        errorMessage: '',
        insufficientFunds: false,
        purchaseDone: false,
    }),

    actions: {
        getDmTexts() {
            axios.get('/profile/dmTexts/get').then(response => {
                this.dm_texts = response.data
            })
        },
        getTimeReload() {
            axios.get('/profile/timeReload/get').then(response => {
                this.timeReload = response.data
            })
        },
        getTransactions() {
            axios.get('/profile/paymentHistory/get').then(response => {
                this.transactions = response.data.map(transaction => {
                    transaction.description = transaction.description + transaction.profile_url
                    return transaction
                })
            })
        },
        getPurchases() {
            return axios.get('/profile/purchases/get')
        },
        filterPurchases(data) {
            this.media_purchases = data.filter(transaction => transaction.type === 'Store Purchase')
            this.subscriptions = data.filter(transaction => transaction.type === 'Subscription')
            this.tips = data.filter(transaction => transaction.type === 'Tip')
            this.wallets = data.filter(transaction => transaction.type === 'Wallet')
            this.live_shows = data.filter(transaction =>
                ['Custom Live Tip', 'Live Tip Item', 'Live Show Access'].includes(transaction.type),
            )
        },
        async expire(creator_id) {
            await axios.post('/profile/purchases/expire', { creator_id })
        },

        async makePurchasePost(post) {
            this.loading = true
            try {
                const response = await axios.post(`/billing/${post.id}/purchase`)
                this.isPayWithWallet = this.isPayWithWallet ? 1 : 0
                if (response.data.error === 'NeedAdditionalFundsException') {
                    this.insufficientFunds = true
                    this.loading = false
                    this.purchaseDone = false
                } else if (response.data.error) {
                    this.purchaseDone = false
                    this.errorMessage = response.data.error
                } else {
                    this.loading = false
                    this.purchaseDone = true
                }
            } catch (error) {
                if (error.response) {
                    console.log('catch', error.response)
                    this.errorMessage = error.response.data.message
                }
            } finally {
                this.loading = false
            }
        },

        clearPurchaseResponse() {
            this.errorMessage = ''
            this.insufficientFunds = false
        },
    },

    getters: {
        loadingResponse(state) {
            return state.loading
        },
    },
})
